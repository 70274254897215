import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingComponent from "../../Components/Loading";

// Lazy-loaded Omni routes
const OmniNavigationRouter = lazy(() => import("./Portal/PortalCombinedRoutes"));
const PublicNavigationRouter = lazy(() => import("./Public/NavigationRouter"));

const CombinedRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const history = useHistory();
  const { authentication } = useSelector(state => state)
  let { token, role } = authentication
  useEffect(() => {
    setRoutes([
      {
        path: "/",
        component: token
          ? OmniNavigationRouter
          : PublicNavigationRouter,
        key: "/",
      },
    ]);
  }, [history, token]);

  const RouteConstructor = (path, Component, key, exact) => (
    <Route
      path={path}
      exact={exact}
      render={(props) => <Component {...props} />}
    />
  );

  return (
    <Suspense fallback={<LoadingComponent />}>
      <Switch>
        {routes.map((route) => {
          return RouteConstructor(
            route.path,
            route.component,
            route.key,
            route.exact
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default CombinedRoutes;
