import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./Assets/Css/portal.scss";
import { persistor, store } from "./Redux/New/Redux/Redux";
import "./custom.scss";
import registerServiceWorker from "./registerServiceWorker";
import { SnackbarProvider } from "./Contexts/SnackbarContext";
//fixed

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));
registerServiceWorker();
