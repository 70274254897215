import { ThemeProvider } from "@mui/material";
import "font-awesome/css/font-awesome.css";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { GET_LANGUAGES } from "./APIs/Criteria";
import LoadingComponent from "./Components/Loading";
import MuiSnackbar from "./Components/MuiSnackbar";
import { setLanguage, setMedia } from "./Redux/New/Redux/Reducers/System";
import Routes from "./SM/RoutingConfig/CombinedRoutes";
import { getFileDirectory } from "./SM/Utils/Functions";
import { createCustomTheme } from "./Theme";

function App() {
  const [Loading, SetLoading] = useState(false);
  const dispatch = useDispatch();

  const GetLanguages = async () => {
    try {
      let languageResponse = await GET_LANGUAGES();
      dispatch(setLanguage(languageResponse?.data?.data?.languages));
    } catch (e) {
      console.error(e);
    }
  };

  const getMediaDirectory = async () => {
    try {
      let languageResponse = await getFileDirectory();
      dispatch(setMedia(languageResponse));
    } catch (e) {
      console.error(e);
    }
  };

  const {
    system: { language, media },
  } = useSelector((state) => state);

  useEffect(() => {
    if (language?.length == 0) {
      GetLanguages();
    }
    if (!media || Object.keys(media)?.length === 0) {
      getMediaDirectory();
    }
  }, []);

  useEffect(() => {
    window.process = {
      ...window.process,
    };

    document.documentElement.style.setProperty(
      "--primary-color",
      process.env.REACT_APP_PRIMARY_COLOR
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      process.env.REACT_APP_SECONDARY_COLOR
    );
    document.documentElement.style.setProperty(
      "--grey-color",
      process.env.REACT_APP_GREY_COLOR
    );
    document.documentElement.style.setProperty(
      "--black-color",
      process.env.REACT_APP_BLACK_COLOR
    );
    document.documentElement.style.setProperty(
      "--text-white-color",
      process.env.REACT_APP_TEXT_WHITE
    );
  }, []);

  return (
    <ThemeProvider theme={createCustomTheme({})}>
      <Router history={require("history").createBrowserHistory()}>
        <Helmet>
          <title>{"Monty Mobile Portal"}</title>
          <meta name="description" content={`${"Monty Mobile Portal"}`} />
        </Helmet>
        <MuiSnackbar />
        {Loading ? <LoadingComponent /> : <Routes />}
      </Router>
    </ThemeProvider>
  );
}

export default App;
