import axios from "axios";
import axiosInstance from "./config/axios";

export const SIGN_OUT = async () => {
  return await axiosInstance.post("/member/api/v1/auth/logout");
};

export const VERIFY_EMAIL = async ({ formData }) => {
  try {
    return await axiosInstance.post(`/member/api/v1/user/verify-email`, {
      ...formData,
    });
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(`/member/api/v1/user/verify-email`, {
        ...formData,
      });
    else throw e;
  }
};

export const RESET_PASSWORD = async ({ formData }) => {
  try {
    return await axiosInstance.post(
      `/member/api/v1/user/custom-reset-password`,
      formData
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(
        `/member/api/v1/user/custom-reset-password`,
        formData
      );
    else throw e;
  }
};

export const CHANGE_PASSWORD = async ({ NewPassword }) => {
  try {
    return await axiosInstance.post(`/member/api/v1/user/change-password`, {
      NewPassword,
    });
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(`/member/api/v1/user/change-password`, {
        NewPassword,
      });
    else throw e;
  }
};

export const REQUEST_OTP = async ({ username }) => {
  try {
    return await axiosInstance.post(`/member/api/v1/user/request-resend-otp`, {
      username,
    });
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(
        `/member/api/v1/user/request-resend-otp`,
        { username }
      );
    else throw e;
  }
};

export const SIGN_IN = async ({ username, password }) => {
  return axios.post(
    `${process.env.REACT_APP_API_IDENTITY_URL}/member/api/v1/auth/login`,
    { username, password },
    {
      headers: {
        tenant: `${process.env.REACT_APP_TENANT}`,
      },
    }
  );
};

export const SIGN_IN_URL = async ({ username, password }) => {
  return axios.post(
    `${process.env.REACT_APP_API_IDENTITY_URL}/member/api/v1/auth/login-user`,
    { username, password },
    {
      headers: {
        tenant: `${process.env.REACT_APP_TENANT}`,
      },
    }
  );
};

export const UPLOAD_MEDIA = async ({ token, formData }) => {
  try {
    return await axiosInstance.post(`/member/api/v1/Media/Upload`, formData);
  } catch (e) {
    if (e == "error: 401") {
      return await axiosInstance.post(`/member/api/v1/Media/Upload`, formData);
    } else throw e;
  }
};

export const GET_ALL_CHANNELS = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_API_IDENTITY_URL}/notification/api/admin/v1/Channel/get-all`,
    {
      headers: {
        tenant: process.env.REACT_APP_TENANT,
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const GET_ADDRESS = async ({ clientGuid }) => {
  try {
    return await axiosInstance.get(
      `/member/api/v1/address/client?ClientGuid=${clientGuid}`
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `/member/api/v1/address/client?ClientGuid=${clientGuid}`
      );
    else throw e;
  }
};

export const GET_SMTP_BY_ID = async ({ clientGuid }) => {
  try {
    return await axiosInstance.get(
      `/configuration/api/admin/v1/SmtpConfiguration/get-all?ClientGuid=${clientGuid}`
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `/configuration/api/admin/v1/SmtpConfiguration/get-all?ClientGuid=${clientGuid}`
      );
    else throw e;
  }
};

export const GET_SASS_CONFIG = async ({ path }) => {
  try {
    return await axiosInstance.get(
      `/member/api/admin/v1/client/get-saas-config?BusinessWebUrl=${path}`
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `/member/api/admin/v1/client/get-saas-config?BusinessWebUrl=${path}`
      );
    else throw e;
  }
};
