import axios from "axios";
import { handleMessageError } from "../../SM/Utils/Functions";
import { SignIn, SignOut } from "../../Redux/New/Redux/Reducers/Authentication";
import { SetMenus } from "../../Redux/New/Redux/Reducers/Menus";
import { store } from "../../Redux/New/Redux/Redux";
import { setMedia } from "../../Redux/New/Redux/Reducers/System";

const axiosInstance = axios.create({
  // Your base configuration here
  baseURL: process.env.REACT_APP_API_IDENTITY_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store?.getState()?.authentication?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.tenant =
      config?.baseURL === `${process.env.REACT_APP_API_KEYSTORE_URL}`
        ? `${process.env.REACT_APP_KEYSTORE_TENANT}`
        : `${process.env.REACT_APP_TENANT}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // You can modify the response here as needed
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    window?.dataLayer?.push({
      event: "api_error", // Custom event name
      path: window.location.pathname,
      value: handleMessageError({ e: error, type: "validation" }),
    });
    if (error.response && error.response.status === 401) {
      try {
        const token = await refreshToken(
          store?.getState()?.authentication?.refreshToken
        );
        config.headers.Authorization = `Bearer ${token}`;
        return axiosInstance(config);
      } catch (refreshError) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const refreshToken = (_refreshToken) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_IDENTITY_URL}/member/api/v1/auth/refresh-token`,
      { RefreshToken: _refreshToken },
      {
        headers: {
          Authorization: `Bearer ${store?.getState()?.authentication?.token}`,
          tenant: `${process.env.REACT_APP_TENANT}`,
        },
      }
    )
    .then((res) => {
      if (res?.data?.data && res?.data?.data?.accessToken) {
        let { accessToken, refreshToken, roles, clientId } = res?.data?.data;
        store?.dispatch(
          SignIn({
            token: accessToken,
            refreshToken,
            role: roles,
            clientId: clientId || null,
            username: store?.getState()?.authentication?.username,
            email: store?.getState()?.authentication?.email,
          })
        );
      }
      return res?.data?.data?.accessToken;
    })
    .catch((error) => {
      store?.dispatch(
        SignIn({
          token: null,
          refreshToken: null,
          role: null,
          clientId: null,
        })
      );
      store?.dispatch(SignOut());
      store?.dispatch(SetMenus([]));
      store?.dispatch(setMedia({}));
      window.location.href = "/";
      return Promise.reject(error);
    });
};

export default axiosInstance;
