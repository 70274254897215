import axios from "axios";

import axiosInstance from "./config/axios";

export const GET_CATEGORIES = async () => {
  try {
    return await axiosInstance.get(
      `/configuration/api/admin/v1/Criteria/category/get-all`
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `/configuration/api/admin/v1/Criteria/category/get-all`
      );
    else throw e;
  }
};

export const ADD_INDUSTRY = async ({ token, formData }) => {
  try {
    return await axiosInstance.post(`/configuration/api/v1/Industry`, formData);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(
        `/configuration/api/v1/Industry`,
        formData
      );
    else throw e;
  }
};

export const GET_ALL_INDUSTRIES = async () => {
  try {
    return await axiosInstance.get(`/configuration/api/v1/Industry/get-all`);
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(`/configuration/api/v1/Industry/get-all`);
    else throw e;
  }
};

export const GET_ALL_CITIES_API = async ({ country }) => {
  try {
    return await axiosInstance.get(
      `/configuration/api/v1/city/get-all?countryGuid=${country}`
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `/configuration/api/v1/city/get-all?countryGuid=${country}`
      );
    else throw e;
  }
};

export const GET_ALL_COUNTRIES_API = async () => {
  try {
    return await axiosInstance.get(
      `/configuration/api/v1/Country/get-all?PageIndex=1&pageSize=400`
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `/configuration/api/v1/Country/get-all?PageIndex=1&pageSize=400`
      );
    else throw e;
  }
};

export const GET_ALL_COUNTRIES_API_NO_TOKEN = async () => {
  try {
    return await axiosInstance.get(
      `/configuration/api/v1/Country/get-all?PageIndex=1&pageSize=400`
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `/configuration/api/v1/Country/get-all?PageIndex=1&pageSize=400`
      );
    else throw e;
  }
};

export const GET_CURRENCIES = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_API_IDENTITY_URL}/configuration/api/v1/Currency/get-all`,
    {
      headers: {
        tenant: process.env.REACT_APP_TENANT,
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const GET_ALL_SERVICES = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_API_IDENTITY_URL}/catalog/api/v1/Service/GetAll`,
    {
      headers: {
        tenant: process.env.REACT_APP_TENANT,
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const GET_ALL_CHANNELS = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_API_IDENTITY_URL}/notification/api/admin/v1/Channel/get-all`,
    {
      headers: {
        tenant: process.env.REACT_APP_TENANT,
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const GET_LANGUAGES = async () => {
  try {
    return await axiosInstance.get(
      `/configuration/api/v1/language/get-all?Code=en`
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `/configuration/api/v1/language/get-all?Code=en`
      );
    else throw e;
  }
};

export const GET_ALL_GENDERS_API = async ({ token, formData }) => {
  try {
    return await axiosInstance.post(
      `/configuration/api/v1/criteria/get-all`,
      formData
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.post(
        `/configuration/api/v1/criteria/get-all`,
        formData
      );
    else throw e;
  }
};

export const GET_ALL_CRITERIA_API = async ({ type }) => {
  try {
    return await axiosInstance.get(
      `/configuration/api/admin/v1/criteria/get-all?categoryTags=${[type]}`
    );
  } catch (e) {
    if (e == "error: 401")
      return await axiosInstance.get(
        `/configuration/api/admin/v1/criteria/get-all?categoryTags=${[type]}`
      );
    else throw e;
  }
};
